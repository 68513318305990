import Link from 'next/link'
import Image from 'next/image'
import styles from './styles.module.scss'
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import {RenderIf} from '@/utils'
import {useTranslate} from '@/hooks/useTranslate'
import {HeaderCart} from './icon'

export default function CartLinkPlaceholder({isMobile}: {isMobile: boolean}) {
  const t = useTranslate

  return (
    <Link href='/cart'>
      <div
        className={
          isMobile ? styles['main-header__cart-link__mobile'] : styles['main-header__cart-link']
        }
      >
        <HeaderCart />
        <RenderIf isTrue={!isMobile}>
          <UI_Typography variant='Regular/Reg12'>{t('CART')}</UI_Typography>
        </RenderIf>
      </div>
    </Link>
  )
}
