'use client'

import {useTranslate} from '@/hooks/useTranslate'

// components
import {RenderIf} from '@/utils'
import Image from 'next/image'
import Link from 'next/link'

// styles
import styles from './styles.module.scss'

interface Props {
  isMobile?: boolean
}

const FavoriteLink = ({isMobile = false}: Props) => {
  const t = useTranslate

  return (
    <Link href='/favorite' data-testid='header-favorites-page-link'>
      <div
        className={
          isMobile
            ? styles['main-header__favorite-link__mobile']
            : styles['main-header__favorite-link']
        }
      >
        <Image
          src='/Assets/Images/header/favorite.svg'
          alt={t('FAVORITES')}
          width={24}
          height={24}
        />
        <RenderIf isTrue={!isMobile}>
          <span>{t('FAVORITES')}</span>
        </RenderIf>
      </div>
    </Link>
  )
}

export default FavoriteLink
