import {SimpleObject} from '@/types'
import {isServerSide} from '@/utils'

export function dataLayerInit() {
  if (isServerSide) return
  window.dataLayer = window.dataLayer || []
}

export function pushToDataLayer(itemObject: SimpleObject) {
  if (isServerSide) return

  window.dataLayer = window.dataLayer || []
  if (Object.keys(itemObject || {}).length > 0) {
    window.dataLayer?.push(itemObject)
  }
}
