import {RectShape} from 'react-placeholder/lib/placeholders'

export const PlaceHolder = () => {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
      <RectShape color='#E6E6E6' style={{width: 10, height: 10, borderRadius: '50%'}} />
      <RectShape color='#E6E6E6' style={{width: 23, height: 17, margin: 0}} />
    </div>
  )
}
