import {cleanObject} from '@/utils'
import {DATA_LAYER_EVENTS, pushToDataLayer} from './index'
import {ICart} from '@/types/Cart'
import {Currency} from '@/types/Currency'

const tagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

interface CategoryData {
  index: number
  categoryName: string
  categoryId: number
}

interface ProductType {
  categoryData: CategoryData
  userData: Array<any>
  items: Array<any>
}

export function pushViewDetailPage(product: ProductType) {
  let value = product?.items[0]?.prices?.discount?.value
    ? product.items[0].prices.discount.value
    : product?.items[0]?.prices?.original?.value
    ? product.items[0].prices.original.value
    : product?.items[0]?.sell_price

  pushToDataLayer({
    ecommerce: null,
  })
  pushToDataLayer({
    event: DATA_LAYER_EVENTS.VIEW_ITEM,
    ecommerce: {
      item_id: product.items[0].stock_code,
      currency: product?.items[0]?.currency?.iso3 ? product.items[0].currency.iso3 : 'TRY',
      value: Number(value),
      user_data: product.userData.map((item, id) => {
        return cleanObject({
          email_address: item.email,
          phone_number: item.phone,
          address: cleanObject({
            first_name: item.name,
            last_name: item.surname,
            city: item.province?.province_name,
            region: item.district?.district_name,
            postal_code: item.postal_code,
            country: item.country?.country_name,
          }),
        })
      }),
      send_to: tagManagerId,
      items: product.items.map((item) => {
        return cleanObject({
          item_id: item.stock_code,
          item_name: item.content.name,
          affiliation: '', //we don't have this
          coupon: '', //we don't have this
          discount: item?.prices?.discount?.value ? item.prices.discount.value : 0,
          index: product.categoryData.index || 0,
          item_brand: 'Tofisa',
          item_category: item.category.name,
          item_list_id: product.categoryData.categoryId, //we don't have this field
          item_list_name: product.categoryData.categoryName, //we don't have this field
          item_variant: item.content?.color?.name,
          location_id: '', //we don't have this field
          price: Number(item.sell_price), //we don't have this,
          quantity: item.quantity,
        })
      }),
    },
  })
}

export function pushAddToCartAction(addToCartProduct: ProductType) {
  let value = addToCartProduct?.items[0]?.prices?.discount?.value
    ? addToCartProduct.items[0].prices.discount.value
    : addToCartProduct?.items[0]?.prices?.original?.value
    ? addToCartProduct.items[0].prices.original.value
    : addToCartProduct?.items[0]?.sell_price

  pushToDataLayer({
    ecommerce: null,
  })

  pushToDataLayer({
    event: DATA_LAYER_EVENTS.ADD_TO_CART,
    ecommerce: {
      currency: addToCartProduct?.items[0]?.currency?.iso3
        ? addToCartProduct.items[0].currency.iso3
        : 'TRY',
      value: Number(value),
      user_data: addToCartProduct.userData.map((item, id) => {
        return cleanObject({
          email_address: item.email,
          phone_number: item.phone,
          address: cleanObject({
            first_name: item.name,
            last_name: item.surname,
            city: item.province?.province_name,
            region: item.district?.district_name,
            postal_code: item.postal_code,
            country: item.country?.country_name,
          }),
        })
      }),
      send_to: tagManagerId,
      items: addToCartProduct.items.map((item) => {
        return cleanObject({
          item_id: item.stock_code,
          item_name: item.content.name,
          affiliation: '', //we don't have this
          coupon: '', //we don't have this
          discount: item?.prices?.discount?.value ? item.prices.discount.value : 0,
          index: addToCartProduct.categoryData.index || 0,
          item_brand: 'Tofisa',
          item_category: item.category.name,
          item_list_id: addToCartProduct.categoryData.categoryId, //we don't have this field
          item_list_name: addToCartProduct.categoryData.categoryName, //we don't have this field
          item_variant: item?.content?.color?.name,
          location_id: '', //we don't have this field
          price: Number(item?.sell_price), //we don't have this,
          quantity: item?.quantity,
        })
      }),
    },
  })
}

interface ShippingAddressType {
  value: number
  userData: Array<any>
  items: Array<any>
}

export function pushShippingInformation(address: ShippingAddressType) {
  pushToDataLayer({
    ecommerce: null,
  })
  pushToDataLayer({
    event: DATA_LAYER_EVENTS.ADD_SHIPPING_INFO,
    ecommerce: {
      currency: address.items[0]?.currency?.iso3 ? address.items[0].currency.iso3 : 'TRY',
      value: address.value,
      shipping_tier: 'Ground',
      user_data: address.userData.map((item, id) => {
        return cleanObject({
          email_address: item.email,
          phone_number: item.phone,
          address: cleanObject({
            first_name: item.name,
            last_name: item.surname,
            city: item.province?.province_name,
            region: item.district?.district_name,
            postal_code: item.postal_code,
            country: item.country?.country_name,
          }),
        })
      }),
      send_to: tagManagerId,
      items: address?.items.map((item, index) => {
        return cleanObject({
          item_id: item.stock_code,
          item_name: item.value?.content?.name,
          affiliation: '', //we don't have this
          coupon: '', //we don't have this
          discount: item.value?.discounted_price ? Number(item.value?.discounted_price) : 0,
          index: index || 0,
          item_brand: 'Tofisa',
          item_category: item.value?.category?.name,
          item_variant: item.value?.content?.color?.name,
          item_list_id: item.itemListId,
          item_list_name: item.itemListName,
          location_id: '',
          price: Number(item.value?.sell_price),
          quantity: item.value?.quantity,
        })
      }),
    },
  })
}

interface RemoveProduct {
  userData: Array<any>
  items: ICart[]
  currency: Currency
}

export function pushRemoveFromCart(product: RemoveProduct) {
  pushToDataLayer({
    ecommerce: null,
  })
  pushToDataLayer({
    event: DATA_LAYER_EVENTS.REMOVE_FROM_CART,
    ecommerce: {
      currency: product?.currency?.iso3 ? product.currency.iso3 : 'TRY',
      value: Number(product?.items[0]?.price),
      user_data: product.userData.map((item, id) => {
        return cleanObject({
          email_address: item.email,
          phone_number: item.phone,
          address: cleanObject({
            first_name: item.name,
            last_name: item.surname,
            city: item.province?.province_name,
            region: item.district?.district_name,
            postal_code: item.postal_code,
            country: item.country?.country_name,
          }),
        })
      }),
      send_to: tagManagerId,
      items: product.items.map((item) => {
        return cleanObject({
          item_id: item.stock.product_color_variation.product_color.stock_code,
          item_name: item.content.name,
          discount: item?.discount ? Number(item.discount) : 0,
          index: 0,
          item_brand: 'Tofisa',
          item_category:
            item.stock.product_color_variation.product_color.product.category.contents[0].name,
          item_list_id: item.stock.product_color_variation.product_color.product.category_id,
          item_list_name:
            item.stock.product_color_variation.product_color.product.category.contents[0].name,
          item_variant: item.stock.product_color_variation.product_color.color.contents[0].name,
          price: Number(item.price),
          quantity: item.quantity,
        })
      }),
    },
  })
}

interface CartProducts {
  userData: Array<any>
  items: Array<any>
  currency: Currency
}

export function pushCardProducts(product: CartProducts) {
  let sum = 0
  for (const obj of product.items) {
    sum += parseFloat(obj.price)
  }
  pushToDataLayer({
    ecommerce: null,
  })
  pushToDataLayer({
    event: DATA_LAYER_EVENTS.VIEW_CART,
    ecommerce: {
      currency: product?.currency?.iso3 ? product.currency.iso3 : 'TRY',
      value: sum,
      user_data: product.userData.map((item, id) => {
        return cleanObject({
          email_address: item.email,
          phone_number: item.phone,
          address: cleanObject({
            first_name: item.name,
            last_name: item.surname,
            city: item.province?.province_name,
            region: item.district?.district_name,
            postal_code: item.postal_code,
            country: item.country?.country_name,
          }),
        })
      }),
      send_to: tagManagerId,
      items: product.items.map((item, index) => {
        return cleanObject({
          item_id: item.stock.product_color_variation.product_color.stock_code,
          item_name:
            item.stock.product_color_variation.product_color.product.category.contents[0].name,
          discount: item.value?.discounted_price ? Number(item.value?.discounted_price) : 0,
          index: index,
          item_brand: 'Tofisa',
          item_category:
            item.stock.product_color_variation.product_color.product.category.contents[0].name,
          item_list_id: item.stock.product_color_variation.product_color.product.category_id,
          item_list_name:
            item.stock.product_color_variation.product_color.product.category.contents[0].name,
          item_variant: item.stock.product_color_variation.product_color.color.contents[0].name,
          price: Number(item.price),
          quantity: item.quantity,
        })
      }),
    },
  })
}

interface PurchaseData {
  transactionId: string
  shippingPrice: number
  tax: number
  value: number
  currencyIso3: string
}

interface PurchaseProductType {
  userData: Array<any>
  purchaseData: PurchaseData
  items: Array<any>
}

export function pushPurchaseProducts(product: PurchaseProductType) {
  pushToDataLayer({
    ecommerce: null,
  })
  pushToDataLayer({
    event: DATA_LAYER_EVENTS.PURCHASE,
    ecommerce: {
      transaction_id: product?.purchaseData?.transactionId,
      value: product.purchaseData?.value,
      user_data: product.userData.map((item, id) => {
        return cleanObject({
          email_address: item.email,
          phone_number: item.phone,
          address: cleanObject({
            first_name: item.name,
            last_name: item.surname,
            city: item.province,
            region: item.district,
            postal_code: item.postal_code,
            country: item.country,
          }),
        })
      }),
      send_to: tagManagerId,
      tax: product.purchaseData?.tax,
      shipping: product.purchaseData?.shippingPrice,
      // @ts-ignore
      currency: product?.purchaseData?.currencyIso3 ? product.purchaseData.currencyIso3 : 'TRY',
      items: product.items.map((item, index) => {
        return cleanObject({
          item_id: item.stock_code,
          item_name: item.product_name,
          affiliation: '', //we don't have this
          coupon: '', //we don't have this
          discount: item.discounted_price ? Number(item?.discounted_price) : 0,
          index: index,
          item_brand: 'Tofisa',
          item_category: item.category,
          item_list_id: item.category_id,
          item_list_name: item.category,
          item_variant: item.product_color,
          location_id: '',
          price: Number(item.sell_price),
          quantity: item.quantity,
        })
      }),
    },
  })
}

interface DefaultItems {
  pageType: string
  pageUri: string
}

export function pushDefultPageView(item: DefaultItems) {
  pushToDataLayer({
    ecommerce: null,
  })
  pushToDataLayer({
    event: DATA_LAYER_EVENTS.PAGE_VIEW,
    ecommerce: {
      ecomm_pagetype: item.pageType,
      ecomm_page_uri: item.pageUri,
      send_to: tagManagerId,
    },
  })
}
interface ProductListData {
  itemListId: Number
  itemListName: String
}
interface ProductListType {
  userData: Array<any>
  productListData: ProductListData
  items: Array<any>
}

export function pushProductListItems(product: ProductListType) {
  pushToDataLayer({
    ecommerce: null,
  })
  pushToDataLayer({
    event: DATA_LAYER_EVENTS.VIEW_ITEM_LIST,
    ecommerce: {
      item_list_id: product.productListData.itemListId,
      item_list_name: product.productListData.itemListName,
      user_data: product.userData.map((item, id) => {
        return cleanObject({
          email_address: item.email,
          phone_number: item.phone,
          address: cleanObject({
            first_name: item.name,
            last_name: item.surname,
            city: item.city,
            region: item.region,
            postal_code: item.postal_code,
            country: item.country,
          }),
        })
      }),
      send_to: tagManagerId,
      items: product.items.map((item, index) => {
        return cleanObject({
          item_id: item.stock_code,
          item_name: item.content?.name,
          affiliation: '', //we don't have this
          coupon: '', //we don't have this
          discount: item.discounted_price ? Number(item.discounted_price) : 0,
          index: index || 0,
          item_brand: 'Tofisa',
          item_category: item.category?.name,
          item_list_id: item.category_id, //we don't have this field
          item_list_name: item.category?.name, //we don't have this field
          item_variant: item.content?.color?.name,
          location_id: '', //we don't have this field
          price: Number(item.sell_price), //we don't have this,
          quantity: item.quantity,
        })
      }),
    },
  })
}

interface BeginCheckoutProducts {
  userData: Array<any>
  items: Array<any>
  total: number
  currencyIso3?: string
}

export function pushBeginCheckoutData(product: BeginCheckoutProducts) {
  pushToDataLayer({
    ecommerce: null,
  })

  pushToDataLayer({
    event: DATA_LAYER_EVENTS.BEGIN_CHECKOUT,
    ecommerce: {
      currency: product?.currencyIso3 ? product.currencyIso3 : 'TRY',
      value: product.total,
      user_data: product.userData.map((item, id) => {
        return cleanObject({
          email_address: item.email,
          phone_number: item.phone,
          address: cleanObject({
            first_name: item.name,
            last_name: item.surname,
            city: item.province?.province_name,
            region: item.district?.district_name,
            postal_code: item.postal_code,
            country: item.country?.country_name,
          }),
        })
      }),
      send_to: tagManagerId,
      items: product.items.map((item, index) => {
        return cleanObject({
          item_id: item?.stock?.product_color_variation.product_color.stock_code,
          item_name: item?.stock?.product_color_variation.product_color.contents[0]?.name,
          discount: item.discounted_price ? Number(item.discounted_price) : 0,
          item_brand: 'Tofisa',
          item_category: item.stock.product_color_variation.product_color.product.category.name,
          item_list_id: item.stock.product_color_variation.product_color.product.category_id, //i need it
          item_list_name: item.stock.product_color_variation.product_color.product.category.name, //i need it
          item_variant: item.stock.product_color_variation.product_color.color.contents[0].name,
          price: Number(item.price),
          quantity: item.quantity,
        })
      }),
    },
  })
}

interface SearchType {
  searchTerm: String
}

export function pushSearchListItems(item: SearchType) {
  pushToDataLayer({
    ecommerce: null,
  })
  pushToDataLayer({
    event: DATA_LAYER_EVENTS.SEARCH,
    ecommerce: {
      search_term: item.searchTerm,
      send_to: tagManagerId,
    },
  })
}

export function pushLogin(method: string) {
  pushToDataLayer({
    ecommerce: null,
  })
  pushToDataLayer({
    event: DATA_LAYER_EVENTS.LOGIN,
    ecommerce: {
      method,
    },
  })
}

export function pushRegister(method: string) {
  pushToDataLayer({
    ecommerce: null,
  })
  pushToDataLayer({
    event: DATA_LAYER_EVENTS.SIGN_UP,
    ecommerce: {
      method,
    },
  })
}
