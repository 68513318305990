import {handleGetDefaultCountry} from '@/utils'
import {StoreProduct} from '@/services/lib/types/orders.type'
import {Category} from '@/types/Categories'
import {FavoritedProducts} from '@/types/Product'
import {create} from 'zustand'

const defaultCountryId = handleGetDefaultCountry().id
if (!defaultCountryId) throw new Error('The country_id env value is required')

export interface IGlobalStore {
  reviews: {
    storeReview: {
      showStoreReview: boolean
      storeName: string
      orderId: number
      storeId: number
      storeImage: string
    }
    productReview: {
      showProductReview: boolean
      storeName: string
      orderId: number
      storeId: number
      storeImage: string
      selectedProduct: StoreProduct
    }
  }
  userSetting: {
    showUserSettingModal: boolean
    country_id: number
    language: string
    country_name: string
    getUserSettingLoading: boolean
    currencyName: string
    countryFlagUrl: string
    phoneCode: number
    iso3: string
  }
  favorites: {
    user_id: number
    products: FavoritedProducts
  }
  cart: {
    cartCount: number
    cartErrors: {
      stockId: number
      error: unknown
    } | null
    openPopper: boolean
  }

  searchText: string

  categories: Category[] | null

  mobileSidebarIsOpen: boolean

  // methods
  handleToggleStoreReviewModal: ({
    showStoreReview,
    storeName,
    orderId,
    storeId,
    storeImage,
  }: {
    showStoreReview: boolean
    storeName: string
    orderId: number
    storeId: number
    storeImage: string
  }) => void

  handleToggleProductReviewModal: ({
    showProductReview,
    storeName,
    orderId,
    storeId,
    storeImage,
    selectedProduct,
  }: {
    showProductReview: boolean
    storeName: string
    orderId: number
    storeId: number
    storeImage: string
    selectedProduct: StoreProduct
  }) => void

  handleToggleUserSettingModal: (open: boolean) => void

  handleUpdateUserSetting: ({
    countryFlagUrl,
    country_id,
    country_name,
    currencyName,
    getUserSettingLoading,
    iso3,
    language,
    phoneCode,
  }: {
    country_id: number
    language: string
    country_name: string
    getUserSettingLoading: boolean
    currencyName: string
    countryFlagUrl: string
    phoneCode: number
    iso3: string
  }) => void

  handleUpdateFavorites: ({user_id, products}: {user_id: number; products: {}}) => void

  handleUpdateCart: (count: number) => void

  handleUpdateCartError: (cartErrors: {stockId: number; error: unknown} | null) => void

  handleOpenPopper: (open: boolean) => void

  handleUpdateSearchText: (text: string) => void

  handleUpdateCategories: (categories: Category[]) => void

  handleUpdateMobileSidebarIsOpen: (status: boolean) => void
}

export const globalStoreInitialValue = {
  reviews: {
    storeReview: {
      showStoreReview: false,
      storeName: '',
      orderId: 0,
      storeId: 0,
      storeImage: '',
    },
    productReview: {
      showProductReview: false,
      storeName: '',
      orderId: 0,
      storeId: 0,
      storeImage: '',
      selectedProduct: null as unknown as StoreProduct,
    },
  },
  userSetting: {
    showUserSettingModal: false,
    country_id: +defaultCountryId,
    language: handleGetDefaultCountry().primaryLanguage,
    country_name: '',
    getUserSettingLoading: true,
    currencyName: '',
    countryFlagUrl: '',
    phoneCode: 90,
    iso3: '',
  },
  favorites: {
    user_id: 0,
    products: {},
  },
  cart: {
    cartCount: 0,
    cartErrors: null,
    openPopper: false,
  },
  searchText: '',
  categories: null,
  mobileSidebarIsOpen: false,
}

export const useGlobalStore = create<IGlobalStore>()((set) => ({
  ...globalStoreInitialValue,

  handleToggleStoreReviewModal: ({
    showStoreReview,
    storeName,
    orderId,
    storeId,
    storeImage,
  }: {
    showStoreReview: boolean
    storeName: string
    orderId: number
    storeId: number
    storeImage: string
  }) =>
    set((state) => ({
      reviews: {
        ...state.reviews,
        storeReview: {
          showStoreReview,
          storeName,
          orderId,
          storeId,
          storeImage,
        },
      },
    })),

  handleToggleProductReviewModal: ({
    showProductReview,
    storeName,
    orderId,
    storeId,
    storeImage,
    selectedProduct,
  }: {
    showProductReview: boolean
    storeName: string
    orderId: number
    storeId: number
    storeImage: string
    selectedProduct: StoreProduct
  }) =>
    set((state) => ({
      reviews: {
        ...state.reviews,
        productReview: {
          showProductReview,
          storeName,
          orderId,
          storeId,
          storeImage,
          selectedProduct,
        },
      },
    })),

  handleToggleUserSettingModal: (open: boolean) =>
    set((state) => ({
      userSetting: {
        ...state.userSetting,
        showUserSettingModal: open,
      },
    })),

  handleUpdateUserSetting: ({
    countryFlagUrl,
    country_id,
    country_name,
    currencyName,
    getUserSettingLoading,
    iso3,
    language,
    phoneCode,
  }) =>
    set((state) => ({
      userSetting: {
        ...state.userSetting,
        countryFlagUrl,
        country_id,
        country_name,
        currencyName,
        getUserSettingLoading,
        iso3,
        language,
        phoneCode,
      },
    })),

  handleUpdateFavorites: ({user_id, products}: {user_id: number; products: {}}) =>
    set(() => ({
      favorites: {
        user_id,
        products,
      },
    })),

  handleUpdateCart: (count: number) => set((state) => ({cart: {...state.cart, cartCount: count}})),
  handleUpdateCartError: (cartErrors: {stockId: number; error: unknown} | null) =>
    set((state) => ({cart: {...state.cart, cartErrors}})),

  handleOpenPopper: (open: boolean) => set((state) => ({cart: {...state.cart, openPopper: open}})),

  handleUpdateSearchText: (text: string) => set(() => ({searchText: text})),

  handleUpdateCategories: (categories: Category[]) => set(() => ({categories})),

  handleUpdateMobileSidebarIsOpen: (value: boolean) => set(() => ({mobileSidebarIsOpen: value})),
}))
