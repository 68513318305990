import {FC, ReactNode} from 'react'
import {getLanguage} from './i18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/tr'

import trMessages from './messages/tr.json'
import enMessages from './messages/en.json'

const allMessages = {
  tr: trMessages,
  en: enMessages,
}

type WithChildren = {
  children?: ReactNode
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = getLanguage()
  const messages = allMessages[locale as keyof typeof allMessages]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}
