'use client'

import {defaultLocale} from '@/i18n'
import {I18nContextProvider} from '@/i18n/i18n'
import {I18nProvider} from '@/i18n/i18nProvider'
import {getClientSideCookie} from '@/utils'
import {isAppRouter} from '@/utils/helpers/router.helper'
import {NextIntlClientProvider} from 'next-intl'

export const TranslateProvider = ({
  messages,
  children,
}: {
  messages: any
  children: React.ReactNode
}) => {
  const locale = getClientSideCookie('NEXT_LOCALE') ?? defaultLocale

  if (isAppRouter()) {
    return (
      <NextIntlClientProvider locale={locale} messages={messages}>
        {children}
      </NextIntlClientProvider>
    )
  } else {
    return (
      <I18nContextProvider>
        <I18nProvider>{children}</I18nProvider>
      </I18nContextProvider>
    )
  }
}
