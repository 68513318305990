'use client'

import {MenuItem} from '@/services/menus.service'
import {useGlobalStore} from '@/store'
import dynamic from 'next/dynamic'
import {useState} from 'react'
import {useShallow} from 'zustand/react/shallow'
import UserProfileLink from '../header-actions/user-profile-link'

// components
const Logo = dynamic(() => import('../logo'))
const MobileSidebar = dynamic(() => import('../mobile-sidebar'), {ssr: false})
const Menu = dynamic(() => import('./menu'))
const MenuButton = dynamic(() => import('./menu-button'))
const CartLink = dynamic(
  () => import('@/components/header/_lib/components/header-actions/cart-link'),
  {
    ssr: false,
    loading: () => <CartLinkPlaceholder isMobile={true} />,
  }
)

// styles
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import useHeader from '../../useHeader'
import CartLinkPlaceholder from '../header-actions/cart-link/placeholder'
import SearchLink from '../header-actions/search-link/SearchLink'
import HeaderBackButton from './header-back-button/HeaderBackButton'
import styles from './styles.module.scss'

const MobileHeader = ({
  primaryMenuItems,
  secondaryMenuItems,
}: {
  primaryMenuItems?: MenuItem[]
  secondaryMenuItems?: MenuItem[]
}) => {
  const {get} = useHeader({fetchData: !primaryMenuItems || !secondaryMenuItems})

  const {mobileSidebarIsOpen, handleUpdateMobileSidebarIsOpen} = useGlobalStore(
    useShallow((state) => ({
      mobileSidebarIsOpen: state.mobileSidebarIsOpen,
      handleUpdateMobileSidebarIsOpen: state.handleUpdateMobileSidebarIsOpen,
    }))
  )

  // states
  const [enableMenuSearchMode, setEnableMenuSearchMode] = useState(false)

  return (
    <>
      <div className={styles['banner']}>
        <UI_Typography variant='Medium/Med14' color='#fff'>
          ✨Toptan Fiyatına{' '}
          <UI_Typography variant='Regular/Reg14' color='#fff'>
            Satış
          </UI_Typography>
          ✨
        </UI_Typography>
      </div>
      <div className={styles['main-header']} data-testid='main-mobile-header'>
        <div className={styles['main-header__left']}>
          <HeaderBackButton />
          <MenuButton onClick={() => handleUpdateMobileSidebarIsOpen(true)} />
        </div>

        <div>
          <Logo width={93} height={35} />
        </div>

        <div className={styles['main-header__right']}>
          <SearchLink setSearchMode={setEnableMenuSearchMode} />
          <UserProfileLink isMobile={true} />
          <CartLink isMobile={true} />
        </div>
      </div>

      <Menu items={primaryMenuItems || get.menuData?.primaryMenu} isFetching={get.isFetchingMenu} />

      <MobileSidebar
        isOpen={mobileSidebarIsOpen}
        searchMode={enableMenuSearchMode}
        handleClose={() => {
          handleUpdateMobileSidebarIsOpen(false)
          setEnableMenuSearchMode(false)
        }}
        primaryMenuItems={primaryMenuItems || get.menuData?.primaryMenu}
        secondaryMenuItems={secondaryMenuItems || get.menuData?.primaryMenu}
      />
    </>
  )
}

export default MobileHeader
