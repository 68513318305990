export const HeaderSearch = () => (
  <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.5 20.0001C5.55 20.0001 1.5 15.9501 1.5 11.0001C1.5 6.05006 5.55 2.00006 10.5 2.00006C15.45 2.00006 19.5 6.05006 19.5 11.0001C19.5 15.9501 15.45 20.0001 10.5 20.0001ZM10.5 3.50006C6.375 3.50006 3 6.87506 3 11.0001C3 15.1251 6.375 18.5001 10.5 18.5001C14.625 18.5001 18 15.1251 18 11.0001C18 6.87506 14.625 3.50006 10.5 3.50006Z'
      fill='#333333'
    />
    <path
      d='M22.2 23C22.425 23 22.575 22.925 22.725 22.775C23.025 22.475 23.025 22.025 22.725 21.725L17.325 16.325C17.025 16.025 16.575 16.025 16.275 16.325C15.975 16.625 15.975 17.075 16.275 17.375L21.675 22.775C21.825 22.925 21.975 23 22.2 23Z'
      fill='#333333'
    />
  </svg>
)
