/**
 * @file fetchWrapper.helper.ts
 * @description This file contains a custom wrapper for the Fetch API, acting as an interceptor
 * for HTTP requests in the application. It provides additional functionality such as:
 * - Handling base URLs for different services (e.g., search service)
 * - Setting default headers and search parameters
 * - Managing authentication and error handling
 * - Customizing request options and URL parameters
 *
 * The FetchWrapper function serves as the main entry point for making HTTP requests,
 * providing a consistent interface and error handling across the application.
 */

import {removeClientSideCookie} from '@/utils'
import {handleGetDefaultCountry} from '@/utils/helpers'
import * as Lib from '..'
import {defaultLocale} from '@/i18n'
import {captureException} from '@sentry/nextjs'

/**
 * Options for the fetch request.
 * @typedef {Object} FetchOptions
 * @property {RequestInit} [init] - The standard fetch options from RequestInit.
 * @property {Record<string, string | number>} [searchParams] - Additional search parameters to be appended to the URL.
 */
type FetchOptions = RequestInit & {
  searchParams?: Record<string, string | number>
}

/**
 * Type definition for additional data to be included with exceptions.
 */
type ExceptionExtraData = {
  extra: {
    url: string
    options: {
      headers: any
      [key: string]: any
    }
    searchParams?: Record<string, string | number>
    isSearchService?: boolean
    baseURL: string
    response?: Response
  }
  tags: {
    service: string
    environment: string | undefined
  }
}

/**
 * Custom error class for FetchWrapper errors.
 * @extends Error
 */
export class FetchWrapperError extends Error {
  // The Response object associated with the error.
  public data: Response

  /**
   * Creates a new FetchWrapperError instance.
   * @param {Response} data - The Response object associated with the error.
   */
  constructor(data: Response) {
    super('FetchWrapperError')
    this.name = 'FetchWrapperError'
    this.data = data
  }
}

export const FetchWrapper = async <T>({
  url,
  options = {},
  isSearchService,
}: {
  url: string
  options?: FetchOptions
  isSearchService?: boolean
}): Promise<Lib.T.FetchWrapper.FetchResponse<T>> => {
  const baseURL = isSearchService ? Lib.Const.SSR_SearchURL! : Lib.Const.SSR_BaseURL!

  // Default headers for the fetch request
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  // If options is not provided, initialize it as an empty object
  if (!options) options = {}

  // Merge default headers with any custom headers provided in options
  options.headers = {
    ...defaultHeaders,
    ...options.headers,
  }

  // Define default search parameters
  const defaultSearchParams = {
    // Include the sale channel ID
    sale_channel_id: Lib.Const.saleChannelId,
    // prettier-ignore
    // Set the country ID, using the provided value or falling back to the default
    country_id: options.searchParams && options.searchParams['country_id'] ? options.searchParams['country_id'] : Number(handleGetDefaultCountry().id),
  } as Record<string, string | number>

  // Merge default search parameters with the search parameters in the options
  options.searchParams = {
    ...defaultSearchParams,
    ...options.searchParams,
  }

  const urlObject = new URL(
    url,
    // Use baseURL if available, otherwise use fallback to window.location.origin if available, otherwise use 'http://localhost'
    baseURL || (typeof window !== 'undefined' ? window.location.origin : 'http://localhost')
  )

  // Handle search params
  if (options.searchParams) {
    Object.entries(options.searchParams).forEach(([key, value]) => {
      if (value) {
        urlObject.searchParams.append(key, value.toString())
      }
    })
  }

  url = urlObject.toString()

  try {
    const response = await fetch(url, options)

    // Check if the response is ok (status code in the range 200-299)
    if (!response.ok) {
      switch (response.status) {
        case 401: {
          if (typeof window !== 'undefined') {
            removeClientSideCookie('authToken')
            removeClientSideCookie('language')
            removeClientSideCookie('countryId')
            window.location.pathname = `/${defaultLocale}`
          }
          break
        }
        case 404:
          break
        default:
          throw new FetchWrapperError(response)
      }
    }

    const json = await response.json()

    return {
      ...response,
      data: json,
    }
  } catch (error) {
    if (error instanceof FetchWrapperError && ![401, 404, 422].includes(error.data.status)) {
      throw error
    }

    // Create a copy of headers and remove the Authorization header if it exists
    const headers = {...options.headers}
    // @ts-ignore
    delete headers['Authorization']

    // Prepare extra data for exception logging
    const extraData: ExceptionExtraData = {
      extra: {
        url,
        options: {
          ...options,
          headers,
        },
        searchParams: options.searchParams,
        isSearchService,
        baseURL,
      },
      tags: {
        service: isSearchService ? 'search-service' : 'default-service',
        environment: process.env.NODE_ENV,
      },
    }

    // Add response data to extra data if the error is a FetchWrapperError
    if (error instanceof FetchWrapperError) {
      extraData.extra.response = error.data
    }

    captureException(error, extraData)

    throw error
  }
}
