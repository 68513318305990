'use client'

import {isMobileDevice} from '@/app/_lib/csr-helpers'
import {getMenu} from '@/components/header/_lib'
import Search from '@/components/header/_lib/components/desktop-version/main-header/search'
import UI_Badge from '@/components/ui-kit/badge/Badge'
import UI_Button from '@/components/ui-kit/button/Button'
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import {useTranslate} from '@/hooks/useTranslate'
import {useLang} from '@/i18n/i18n'
import {MenuItem, MenusService} from '@/services/menus.service'
import {useGlobalStore} from '@/store'
import {captureException} from '@sentry/nextjs'
import Image from 'next/image'
import Link from 'next/link'
import {useEffect, useState} from 'react'
import ReactPlaceholder from 'react-placeholder'
import {RectShape} from 'react-placeholder/lib/placeholders'
import {useQuery} from 'react-query'
import {useShallow} from 'zustand/react/shallow'

const continueShopping = !!process.env.NEXT_PUBLIC_CONTINUE_SHOPPING_URL
  ? process.env.NEXT_PUBLIC_CONTINUE_SHOPPING_URL
  : '/'

const Custom404 = ({messages}: {messages: string}) => {
  const isMobile = isMobileDevice()
  const [menuItems, setMenuItems] = useState<MenuItem[]>([])
  const [isFetching, setIsFetching] = useState(false)
  const {userSetting} = useGlobalStore(
    useShallow((state) => ({
      userSetting: state.userSetting,
    }))
  )
  const lang = useLang()

  const handleFetchHeaderMenu = async () => {
    try {
      setIsFetching(true)
      const {primaryMenu} = await getMenu({
        country_id: userSetting?.country_id,
        language: lang,
      })
      setMenuItems(primaryMenu)
    } catch (error) {
      captureException(error)
    } finally {
      setIsFetching(false)
    }
  }

  const t = messages
    ? (key: string) => {
        return messages[key as keyof typeof messages]
      }
    : useTranslate

  useEffect(() => {
    handleFetchHeaderMenu()
  }, [])

  return (
    <div className='notfound_container'>
      <div className='notfound_container_header'>
        <Image src='/Assets/Images/Logo.svg' alt='logo' width={144} height={46} />
        <div className='notfound_container_content'>
          <UI_Typography component='p' variant={isMobile ? 'Regular/Reg12' : 'Regular/Reg18'}>
            {t('ERROR_404_LINE_1') as string}
          </UI_Typography>
          <UI_Typography component='p' variant={isMobile ? 'Regular/Reg12' : 'Regular/Reg18'}>
            {t('ERROR_404_LINE_2') as string}
          </UI_Typography>
          <div className='notfound_container_content_search'>
            <Search />
          </div>
          <div className='notfound_container_content_btn'>
            <UI_Button href={continueShopping}>
              <UI_Typography variant={isMobile ? 'Medium/Med16' : 'Medium/Med18'}>
                {t('CONTINUE_SHOPPING') as string}
              </UI_Typography>
            </UI_Button>
          </div>
        </div>
      </div>
      <div className='not_found_categories'>
        {isFetching && !menuItems?.length ? (
          <ReactPlaceholder
            ready={isFetching}
            className='not_found_categories_placeholder'
            showLoadingAnimation={true}
          >
            {Array.from({length: 12}).map(() => (
              <RectShape
                className='not_found_categories_placeholder_item'
                style={{
                  width: '100%',
                  height: 32,
                  backgroundColor: '#e6e6e6',
                  margin: 0,
                  borderRadius: 6,
                }}
              />
            ))}
          </ReactPlaceholder>
        ) : (
          menuItems?.map((menuItem) => (
            <Link href={menuItem.link} key={menuItem.id} className='not_found_categories_link'>
              <UI_Badge label={menuItem.name} variant='orange' key={menuItem.id} />
            </Link>
          ))
        )}
      </div>
      <div className='notfound_container_footer'>
        <Image src='/Assets/Images/404_footer.png' width={326} height={114} alt='footer' />
      </div>
    </div>
  )
}

export default Custom404
