import clsx from 'clsx'
import React from 'react'
import UI_Typography from '../typography/UI_Typography'
import styles from './styles.module.scss'
import useCustomMediaQuery from '@/component-v2/core/IsLessThanMid'

export interface IBadge {
  icon?: React.ReactNode
  label: string
  variant: 'success' | 'danger' | 'warning' | 'info' | 'orange'
  isActive?: boolean
}

const variantClasses = {
  orange: styles['orange'],
  info: styles['info'],
  warning: styles['warning'],
  danger: styles['danger'],
  success: styles['success'],
}

const isActiveMapper = {
  'true-orange': styles['active_orange'],
  'true-info': styles['active_info'],
  'true-warning': styles['active_warning'],
  'true-danger': styles['active_danger'],
  'true-success': styles['active_success'],
}

const UI_Badge = (props: IBadge) => {
  const {icon, label, variant, isActive = false} = props
  const isMobile = useCustomMediaQuery(500)
  return (
    <div
      className={clsx(
        styles.container,
        variantClasses[variant],
        isActive && isActiveMapper[`${isActive}-${variant}`]
      )}
    >
      {icon && <div>{icon}</div>}
      <UI_Typography variant={isMobile ? 'Medium/Med10' : 'Medium/Med12'}>{label}</UI_Typography>
    </div>
  )
}

export default UI_Badge
