import React, {Dispatch, SetStateAction, useCallback} from 'react'
import {HeaderSearch} from './icon'
import {useGlobalStore} from '@/store'
import {useShallow} from 'zustand/react/shallow'

type Props = {
  setSearchMode: Dispatch<SetStateAction<boolean>>
}

const SearchLink = ({setSearchMode}: Props) => {
  const {handleUpdateMobileSidebarIsOpen} = useGlobalStore(
    useShallow((state) => ({
      handleUpdateMobileSidebarIsOpen: state.handleUpdateMobileSidebarIsOpen,
    }))
  )

  const handleOpenSearch = useCallback(() => {
    handleUpdateMobileSidebarIsOpen(true)
    setSearchMode(true)
  }, [])

  return (
    <div onClick={handleOpenSearch}>
      <HeaderSearch />
    </div>
  )
}

export default SearchLink
