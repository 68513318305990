'use client'

import {usePathname, useRouter} from 'next/navigation'
import {useMemo} from 'react'
import {HeaderArrowLeft} from './icon'
import styles from './styles.module.scss'

const HeaderBackButton = () => {
  const pathname = usePathname()
  const router = useRouter()

  const isHomePage = useMemo(() => pathname === '/tr-TR', [pathname])

  const handleGoBack = () => {
    if (window.history.length > 1) {
      router.back()
    } else {
      router.push('/')
    }
  }
  return (
    <>
      {!isHomePage && (
        <div className={styles['back']} onClick={handleGoBack}>
          <HeaderArrowLeft />
        </div>
      )}
    </>
  )
}

export default HeaderBackButton
