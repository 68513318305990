import {handleGetDefaultCountry} from '@/utils/helpers'
import * as Lib from './lib'

export class CartsService {
  private apiCaller

  endpoint = '/cards'
  anonymousRoute = '/cards/anonymous'
  matchRoute = '/cards/match-with-anonymous-user'

  constructor(token: string, {country_id}: Lib.T.ApiCaller.DefaultParams) {
    this.apiCaller = Lib.Helper.ApiCaller({
      isSearchService: false,
      accessToken: <string>token!,
      defaultParams: {
        country_id: country_id ? country_id : Number(handleGetDefaultCountry().id),
      },
    })
  }

  // # Add #
  async addToCartWithAuth(
    data: Lib.T.Carts.AddToCartArgs
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.Carts.AddToCart>> {
    return await this.apiCaller.post(this.endpoint, data)
  }

  async addToCartAnonymous(
    data: Lib.T.Carts.AddToCartArgs,
    uuid?: Lib.T.Carts.Uuid
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.Carts.AddToCart>> {
    if (uuid) {
      return await this.apiCaller.post(this.anonymousRoute, data, {
        headers: {
          UUID: uuid,
        },
      })
    }
    return await this.apiCaller.post(this.anonymousRoute, data)
  }

  // # Delete #
  async deleteCartItem(
    isLogin: boolean,
    data: Lib.T.Carts.DeleteCartItemData,
    uuid: Lib.T.Carts.Uuid
  ): Promise<Lib.T.ApiCaller.Response<[]>> {
    if (isLogin) {
      return await this.apiCaller.delete(this.endpoint, {
        data: data,
      })
    } else {
      return await this.apiCaller.delete(this.anonymousRoute, {
        data: data,
        headers: {
          UUID: uuid,
        },
      })
    }
  }

  // # List Summery #
  async getCartListSummery(
    isLogin: boolean,
    uuid: Lib.T.Carts.Uuid
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.Carts.GetCartList>> {
    if (isLogin) {
      return await this.apiCaller.get(`${this.endpoint}/summery`)
    } else {
      return await this.apiCaller.get(`${this.endpoint}/summery`, {
        headers: {
          UUID: uuid,
        },
      })
    }
  }

  // # List #
  async getCartList(
    isLogin: boolean,
    uuid: Lib.T.Carts.Uuid
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.Carts.GetCartList>> {
    if (isLogin) {
      return await this.apiCaller.get(`${this.endpoint}`)
    } else {
      return await this.apiCaller.get(`${this.anonymousRoute}`, {
        headers: {
          UUID: uuid,
        },
      })
    }
  }

  // # Length #
  async getCartsLength(
    isLogin: boolean,
    uuid: Lib.T.Carts.Uuid
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.Carts.GetCartsLength>> {
    if (isLogin) {
      return await this.apiCaller.get(`${this.endpoint}/length`)
    } else {
      return await this.apiCaller.get(`${this.anonymousRoute}/length`, {
        headers: {
          UUID: uuid,
        },
      })
    }
  }

  // # Match #
  async matchCartListAnonymousWithAuth(
    uuid: Lib.T.Carts.Uuid
  ): Promise<Lib.T.ApiCaller.Response<any>> {
    return await this.apiCaller.post(
      this.matchRoute,
      {},
      {
        headers: {
          UUID: uuid!,
        },
      }
    )
  }

  // # Patch #
  async adjustCartItem(
    isLogin: boolean,
    data: Lib.T.Carts.AdjustCartItemData,
    uuid: Lib.T.Carts.Uuid
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.Carts.AdjustCartItem>> {
    if (isLogin) {
      return await this.apiCaller.patch(this.endpoint, data)
    } else {
      return await this.apiCaller.patch(this.anonymousRoute, data, {
        headers: {
          UUID: uuid,
        },
      })
    }
  }

  async getCartHistory(
    isLogin: boolean,
    uuid: Lib.T.Carts.Uuid
  ): Promise<Lib.T.ApiCaller.Response<number[]>> {
    if (isLogin) {
      return await this.apiCaller.get(`${this.endpoint}/history`)
    } else {
      return await this.apiCaller.get(`${this.anonymousRoute}/history`, {
        headers: {
          UUID: uuid,
        },
      })
    }
  }
}
