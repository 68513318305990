'use client'

import {useRouter} from 'next/router'

/**
 * Determines if the application is using the App Router.
 * This function should only be called in the browser environment.
 *
 * @returns {boolean} True if the App Router is being used, false otherwise.
 * @throws {Error} If called in a non-browser environment.
 */
export const isAppRouter = (): boolean => {
  // Check if we're in a browser environment
  if (typeof window === 'undefined') return false
  try {
    // Attempt to use the useRouter hook which is only available in the Pages Router
    // This will throw an error if we're using the App Router
    const router = useRouter()
    // If we reach this point, we're using the Pages Router
    return false
  } catch {
    // If useRouter() throws an error, we're using the App Router
    return true
  }
}
