import {generateLocaleString} from '@/i18n'
import {useGlobalStore} from '@/store'
import Image from 'next/image'
import Link from 'next/link'
import {useShallow} from 'zustand/react/shallow'
import {LogoSvg} from './logoSvg'
import {isMobileDevice} from '@/app/_lib/csr-helpers'

interface Props {
  width: number
  height: number
  className?: string
  onClick?: () => void
}

const Logo = ({width, height, className, onClick}: Props) => {
  const {userSetting} = useGlobalStore(
    useShallow((state) => ({
      userSetting: state.userSetting,
    }))
  )

  return (
    <Link
      href={`/${generateLocaleString(userSetting.language, userSetting.country_id)}`}
      className={className}
      onClick={() => {
        onClick && onClick()
      }}
    >
      {isMobileDevice() ? (
        <LogoSvg />
      ) : (
        <Image
          src='/Assets/Images/Logo.svg'
          alt='Tofisa'
          width={width}
          height={height}
          className='object-fit-contain'
          quality={100}
        />
      )}
    </Link>
  )
}

export default Logo
