import clsx from 'clsx'
import {ButtonHTMLAttributes, ReactNode} from 'react'
import styles from './styles.module.scss'
import UI_Typography from '../typography/UI_Typography'
import {Spinner} from './Spinner'
import Link from 'next/link'
import {isMobileDevice} from '@/app/_lib/csr-helpers'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  children: React.ReactNode
  variant?: keyof typeof variantClasses
  icon?: ReactNode
  loading?: boolean
  href?: string
}

const variantClasses = {
  primary: styles['primary'],
  success: styles['success'],
  'outline-primary': styles['outline-primary'],
  ghost: styles['ghost'],
  gradient: styles['gradient'],
}

const UI_Button = (props: IButton) => {
  const {
    variant = 'primary',
    disabled,
    className,
    children,
    icon,
    loading,
    href,
    ...restProps
  } = props
  const isMobile = isMobileDevice()
  const commonProps = {
    className: clsx(
      styles.button,
      disabled || loading ? styles.disabled : undefined,
      variantClasses[variant],
      className
    ),
    disabled: disabled || loading,
    ...restProps,
  }

  const content = (
    <UI_Typography
      variant={isMobile ? 'Medium/Med16' : 'Medium/Med18'}
      className={styles['children']}
    >
      {loading ? (
        <div className={styles['spinner']}>
          <Spinner />
        </div>
      ) : (
        <>
          {icon}
          {children}
        </>
      )}
    </UI_Typography>
  )

  return href ? (
    <Link href={href} {...commonProps}>
      {content}
    </Link>
  ) : (
    <button {...commonProps}>{content}</button>
  )
}

export default UI_Button
