'use client'

import {useTranslate} from '@/hooks/useTranslate'
import UI_Typography from '../ui-kit/typography/UI_Typography'
import styles from './styles.module.scss'
import {useFooter} from './useFooter'

export const Footer = () => {
  const {get, on} = useFooter()

  if (get.shouldNotRenderFooter) return null

  return (
    <footer
      className={styles['footer']}
      style={(get.isMobile && on.handleMobileExtraStyle()) || {}}
    >
      <UI_Typography
        variant={get.isMobile ? 'Regular/Reg10' : 'Regular/Reg12'}
        color='var(--secondary-secondary-light)'
        className={styles['rights']}
        component='p'
      >
        {useTranslate('FOOTER_COPYRIGHT', {year: get.currentYear})}
      </UI_Typography>
    </footer>
  )
}
