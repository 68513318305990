export const LogoSvg = () => (
  <svg width='90' height='40' viewBox='0 0 69 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_14712_15789)'>
      <path
        d='M9.88534 22.4553C9.50246 22.6684 9.13259 22.8129 8.77575 22.8879C8.41887 22.9625 8.04881 23 7.66612 23C6.81796 23 6.1195 22.8449 5.57139 22.5352C5.02305 22.2252 4.59068 21.8115 4.27509 21.2929C3.95951 20.7745 3.74223 20.179 3.62338 19.5062C3.50437 18.8325 3.44495 18.1329 3.44495 17.4061V7.77316H0.620735C0.413709 7.77316 0.258568 7.72012 0.155312 7.61311C0.0517137 7.50628 0 7.39131 0 7.26873C0 7.14548 0.0517137 7.03051 0.155312 6.92368C0.258568 6.8172 0.413709 6.76378 0.620735 6.76378H3.44495V2.74083C3.44495 2.5698 3.4994 2.44175 3.6078 2.35597C3.71638 2.27055 3.83299 2.2304 3.95693 2.23606C4.08128 2.24136 4.19752 2.28664 4.30625 2.37224C4.41484 2.45731 4.46913 2.58059 4.46913 2.74083V17.4063C4.46913 17.9726 4.50287 18.5281 4.56998 19.0734C4.63727 19.6182 4.78214 20.107 5.00475 20.54C5.22719 20.9726 5.55047 21.3224 5.97482 21.5898C6.39881 21.8571 6.96268 21.9903 7.66612 21.9903C7.95584 21.9903 8.27913 21.9424 8.63582 21.846C8.99284 21.7503 9.27985 21.6376 9.49734 21.5098C9.65263 21.4351 9.79989 21.4216 9.93945 21.4698C10.0792 21.5177 10.18 21.598 10.242 21.7099C10.3042 21.8224 10.3119 21.948 10.2657 22.0865C10.2189 22.2253 10.092 22.3481 9.88534 22.4553ZM5.54019 7.77316H8.70588C8.90227 7.77316 9.04989 7.72012 9.14798 7.61311C9.24647 7.50628 9.29561 7.39131 9.29561 7.26873C9.29561 7.14548 9.24647 7.03051 9.14798 6.92368C9.04989 6.8172 8.90227 6.76378 8.70588 6.76378H5.54019V7.77316Z'
        fill='#5B5B5B'
      />
      <path
        d='M34.7294 1.74672C34.5846 1.61867 34.4163 1.51166 34.2252 1.42624C34.0336 1.34081 33.8265 1.27414 33.6044 1.22585C33.3818 1.17792 33.1568 1.14325 32.9292 1.12168C32.7017 1.10028 32.4897 1.08966 32.2931 1.08966C31.5794 1.08966 30.9946 1.19914 30.5394 1.41952C30.0843 1.63883 29.7275 1.94445 29.4687 2.33603C29.21 2.72708 29.0317 3.19135 28.9334 3.72761C28.8353 4.26316 28.786 4.84752 28.786 5.48015V22.3106C28.786 22.5351 28.7259 22.7061 28.605 22.8235C28.4846 22.9412 28.3481 22.9997 28.1962 22.9997C28.0444 22.9997 27.9081 22.9438 27.7875 22.8313C27.6668 22.7192 27.6067 22.5457 27.6067 22.3106V6.66762H24.9376C24.7513 6.66762 24.6143 6.61758 24.5263 6.51818C24.4385 6.41773 24.3968 6.30434 24.4021 6.17876C24.4074 6.05179 24.4566 5.93983 24.5496 5.83971C24.6427 5.73908 24.7823 5.68994 24.9686 5.68994H27.6067V5.48034C27.6067 4.74155 27.6824 4.04189 27.8342 3.38325C27.9867 2.7239 28.2454 2.14236 28.6101 1.63901C28.9755 1.13529 29.4531 0.735937 30.0429 0.442164C30.6324 0.146798 31.3723 0 32.2619 0C32.8828 0 33.4491 0.0721608 33.9611 0.216482C34.4733 0.360627 34.9415 0.598334 35.3654 0.929777C35.5206 1.03643 35.6059 1.16465 35.6218 1.31428C35.6372 1.46391 35.6059 1.59214 35.5285 1.69896C35.451 1.80579 35.3372 1.87247 35.187 1.89935C35.0372 1.92588 34.8848 1.8753 34.7294 1.74672ZM29.9497 5.68975H32.9448C33.131 5.68975 33.2733 5.73893 33.3714 5.83956C33.4698 5.93964 33.519 6.0516 33.519 6.17861C33.519 6.30415 33.4698 6.41755 33.3714 6.518C33.2733 6.61739 33.131 6.66747 32.9448 6.66747H29.9497V5.68975Z'
        fill='#5B5B5B'
      />
      <path
        d='M36.7004 7.10024C36.7004 6.90797 36.7567 6.7663 36.8685 6.67556C36.9807 6.58468 37.1006 6.53693 37.2277 6.53126C37.3555 6.52579 37.4746 6.56858 37.5876 6.65947C37.6991 6.7502 37.7553 6.88659 37.7553 7.06802V22.4229C37.7553 22.6155 37.6991 22.7567 37.5876 22.8478C37.4746 22.9385 37.3555 22.984 37.2277 22.984C37.1006 22.984 36.9807 22.9385 36.8685 22.8478C36.7567 22.7565 36.7004 22.6155 36.7004 22.4229V7.10024Z'
        fill='#5B5B5B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.2172 12.4493C26.174 12.7079 26.1404 12.9691 26.0857 13.2254C25.881 14.187 25.4135 14.9977 24.7422 15.6889C22.4639 18.0345 20.1849 20.3792 17.9068 22.7253C17.7835 22.8523 17.6534 22.9565 17.452 22.943C17.452 22.5488 17.4506 22.1623 17.4542 21.7757C17.4545 21.7425 17.4808 21.703 17.5056 21.6775C19.684 19.4361 21.8656 17.1984 24.042 14.9553C25.269 13.6909 25.5765 11.9229 24.8049 10.3248C24.2194 9.11185 23.2463 8.38563 21.9413 8.18826C20.6581 7.99407 19.5549 8.40405 18.6327 9.34036C18.3815 9.59557 18.1356 9.85626 17.8843 10.1117C17.6244 10.3761 17.3585 10.3718 17.0918 10.1109C16.6675 9.69568 16.2708 9.24219 15.8083 8.87787C14.3293 7.71301 12.0921 7.94067 10.8213 9.34656C10.2665 9.96062 9.91337 10.6725 9.80003 11.5075C9.79215 11.5653 9.78035 11.6226 9.76956 11.6804C9.76869 11.6851 9.76288 11.6888 9.75242 11.7011H8.76489C8.94864 9.91872 9.7757 8.57843 11.2732 7.70559C12.3123 7.1 13.4382 6.95638 14.6057 7.20754C15.7771 7.45956 16.6951 8.14385 17.4789 9.06339C17.7119 8.83222 17.9241 8.61399 18.1443 8.4051C19.9674 6.67569 22.8033 6.67341 24.6308 8.39944C25.5315 9.25015 26.0484 10.3037 26.1871 11.5561C26.1928 11.6076 26.207 11.6582 26.2172 11.7093V12.4493Z'
        fill='#F26B26'
      />
      <path
        d='M51.994 16.6874C51.9425 16.0895 51.8161 15.5129 51.6149 14.9563C51.4137 14.4008 51.1443 13.8921 50.8072 13.4305C50.4706 12.9692 50.0689 12.5665 49.6045 12.2216C49.1389 11.878 48.6174 11.6226 48.0385 11.4565C47.5464 11.3275 47.0489 11.2681 46.547 11.2614V12.0481C46.9839 12.0649 47.4015 12.1176 47.7974 12.2165C48.3537 12.3681 48.8484 12.6137 49.2802 12.9544C49.7127 13.2941 50.0766 13.6933 50.3733 14.1512C50.6696 14.6093 50.8933 15.1098 51.0433 15.6545C51.194 16.1995 51.263 16.7495 51.2521 17.3054C51.2412 17.8609 51.1443 18.4099 50.9612 18.9511C50.7786 19.4922 50.5036 19.9893 50.1368 20.4428C49.6982 20.9879 49.152 21.4092 48.5005 21.7083C47.8482 22.0068 47.1561 22.157 46.4239 22.157C45.6918 22.157 45.0047 22.0068 44.3645 21.7083C43.7235 21.4092 43.1798 20.9879 42.7332 20.4428C42.4553 20.0949 42.2295 19.7226 42.0573 19.325C41.8855 18.9283 41.7624 18.5174 41.6894 18.0944C41.6248 17.7175 41.6066 17.3376 41.6223 16.9543H40.84C40.8106 18.4959 41.2406 19.8227 42.1401 20.9311C42.6674 21.5818 43.299 22.0735 44.0343 22.406C44.7712 22.739 45.5676 22.9053 46.4239 22.9053C47.2737 22.9053 48.0669 22.739 48.8033 22.406C49.5384 22.0735 50.1703 21.5818 50.6979 20.9311C51.649 19.758 52.0819 18.3438 51.994 16.6874Z'
        fill='#5B5B5B'
      />
      <path
        d='M42.5654 9.9489C42.6035 10.3957 42.6979 10.8272 42.8486 11.2427C42.9986 11.6581 43.2005 12.0396 43.4522 12.384C43.7046 12.7296 44.0042 13.0299 44.351 13.2874C44.6989 13.5444 45.09 13.7354 45.5222 13.8606C45.8897 13.9563 46.262 14.0005 46.6375 14.0062V13.4174C46.3114 13.4061 45.9987 13.3654 45.7023 13.2915C45.2862 13.1792 44.9172 12.9942 44.5936 12.74C44.2711 12.486 43.998 12.1869 43.7761 11.8451C43.5553 11.5028 43.3887 11.1284 43.2761 10.7209C43.1635 10.3136 43.1112 9.90201 43.1196 9.48622C43.1279 9.07077 43.2005 8.66132 43.3367 8.25614C43.4744 7.8525 43.6803 7.48003 43.9537 7.14136C44.2817 6.73401 44.6895 6.41796 45.1776 6.19511C45.6649 5.9719 46.1824 5.85907 46.7301 5.85907C47.277 5.85907 47.7909 5.9719 48.2702 6.19511C48.7492 6.41796 49.1552 6.73401 49.4897 7.14136C49.6982 7.40115 49.8667 7.67936 49.9952 7.97598C50.1241 8.27347 50.2153 8.57977 50.2701 8.89762C50.3184 9.17902 50.3326 9.46323 50.3199 9.74852H50.9046C50.9271 8.59729 50.6057 7.60438 49.9338 6.77613C49.5387 6.28938 49.0666 5.92186 48.5164 5.67284C47.9652 5.42415 47.3707 5.29947 46.7301 5.29947C46.0942 5.29947 45.5019 5.42415 44.9513 5.67284C44.4015 5.92186 43.9287 6.28938 43.5346 6.77613C42.8225 7.65284 42.4993 8.7103 42.5654 9.9489Z'
        fill='#5B5B5B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.016 4.0663V3.97346C36.0197 3.94623 36.0233 3.91881 36.0283 3.89173C36.0831 3.59001 36.3325 3.36416 36.6306 3.34647C36.8318 3.33427 37.0018 3.40519 37.142 3.55323C37.1736 3.58665 37.2001 3.62521 37.2295 3.66235C37.2422 3.64396 37.2553 3.62468 37.2691 3.60611C37.4481 3.3638 37.776 3.27413 38.043 3.399C38.2616 3.50087 38.3949 3.67526 38.4312 3.92144C38.4631 4.13704 38.4054 4.3295 38.258 4.4846C37.9409 4.81765 37.6192 5.14556 37.2989 5.47538C37.2923 5.48228 37.2854 5.48918 37.2778 5.49503C37.2527 5.5136 37.2251 5.51874 37.1975 5.50565C37.1812 5.49803 37.1667 5.48532 37.1543 5.47237C36.8355 5.14376 36.5159 4.81532 36.198 4.48565C36.1249 4.41015 36.0737 4.31942 36.0454 4.21646C36.0321 4.16747 36.0254 4.11653 36.016 4.0663Z'
        fill='#F26B26'
      />
      <path
        d='M62.1254 7.42973C62.5917 7.45096 63.0438 7.51004 63.4836 7.60592C63.9234 7.70213 64.3654 7.85157 64.8102 8.05479C65.8137 8.55693 66.5719 9.25445 67.0836 10.1464C67.5957 11.0385 67.8826 12.0565 67.9447 13.1996V21.9993C67.9447 22.3731 68.1157 22.5604 68.4571 22.5604C68.6223 22.5604 68.7545 22.5121 68.8526 22.4159C68.951 22.3198 69 22.181 69 21.9995V7.09283C69 6.91136 68.951 6.77253 68.8526 6.67614C68.7545 6.58026 68.6223 6.53199 68.4571 6.53199C68.1157 6.53199 67.9447 6.71893 67.9447 7.09283V10.2345C67.4896 9.0058 66.755 8.0387 65.7414 7.33352C65.193 7.00209 64.6163 6.75926 64.0113 6.60397C63.4063 6.4492 62.7777 6.36132 62.1254 6.33956C61.7016 6.32914 61.2956 6.34523 60.9078 6.38765C60.704 6.41049 60.5017 6.44407 60.2991 6.48743V7.64995C60.903 7.48334 61.5113 7.40817 62.1254 7.42973Z'
        fill='#5B5B5B'
      />
      <path
        d='M60.7861 22.7069C61.3875 22.8109 61.9871 22.8406 62.5837 22.7843C62.7617 22.7675 62.9342 22.7348 63.1078 22.7069H60.7861Z'
        fill='#5B5B5B'
      />
      <path
        d='M65.7414 21.7584C66.1656 21.4701 66.5436 21.1281 66.8744 20.7332V19.2742C66.6152 19.6375 66.3152 19.9689 65.9738 20.268C65.6324 20.5673 65.245 20.8241 64.8103 21.0374C64.3135 21.2724 63.8119 21.4514 63.305 21.5747C62.798 21.6971 62.2809 21.7478 61.7528 21.7268C61.0705 21.6732 60.4135 21.5315 59.7823 21.302C59.1512 21.072 58.5719 20.7487 58.0443 20.332C57.0619 19.5199 56.3607 18.5798 55.9419 17.5112C55.5229 16.4431 55.3387 15.305 55.391 14.0974C55.4012 13.5736 55.4735 13.0342 55.6082 12.4785L55.6511 12.4343H54.5423C54.5031 12.6177 54.4701 12.8031 54.4443 12.9916C54.3927 13.3653 54.3564 13.7447 54.3357 14.1295C54.3154 15.0374 54.4007 15.9062 54.5914 16.7341C54.7824 17.5623 55.078 18.3264 55.4764 19.0264C55.8744 19.7259 56.3788 20.3487 56.9889 20.8934C57.5994 21.4385 58.3134 21.8819 59.1305 22.2236C59.6839 22.4535 60.2363 22.6117 60.7861 22.7069H63.1078C64.0487 22.5546 64.9294 22.2455 65.7414 21.7584Z'
        fill='#5B5B5B'
      />
    </g>
    <defs>
      <clipPath id='clip0_14712_15789'>
        <rect width='69' height='23' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
